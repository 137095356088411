const files = require.context('./modules', true, /\.url\.js$/);

const { VUE_APP_SERVER, VUE_APP_API_NEW } = process.env;

export default files.keys().reduce(
  (urls, key) => {
    return {
      ...urls,
      ...files(key).default,
    };
  },
  {
    host: VUE_APP_SERVER,
    apiPrev: VUE_APP_API_NEW,
    baseURL: `${VUE_APP_SERVER}${VUE_APP_API_NEW}`,
  },
);
