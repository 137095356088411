// 客户
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  // 客户列表
  {
    path: `${baseRoute}/customer/manage`,
    name: 'customerManage',
    meta: {
      title: '客户管理',
      auth: true,
      cache: true,
      keepAlive: true,
      isBack: false
    },
    component: _import('crm/customer/manage/list'),
  },
  // 客户详情
  {
    path: `${baseRoute}/customer/detail`,
    name: 'customerDetail',
    meta: {
      title: '客户详情',
      auth: true,
      cache: true,
    },
    component: _import('crm/customer/manage/detail'),
  },
  {
    path: `${baseRoute}/customer/follow`,
    name: 'customerFollow',
    meta: {
      title: '客户跟进',
      auth: true,
      cache: true,
    },
    component: _import('crm/customer/follow'),
  },
  {
    path: `${baseRoute}/customer/level`,
    name: 'customerLevel',
    meta: {
      title: '客户等级管理',
      auth: true,
      cache: true,
    },
    component: _import('crm/customer/level'),
  },
  {
    path: `${baseRoute}/customer/label`,
    name: 'customerLabel',
    meta: {
      title: '客户标签管理',
      auth: true,
      cache: true,
    },
    component: _import('crm/customer/label'),
  },
  {
    path: `${baseRoute}/customer/h5`,
    name: 'customerH5',
    meta: {
      title: '客户H5账号管理',
      auth: true,
      cache: true,
    },
    component: _import('crm/customer/h5'),
  },
  {
    path: `${baseRoute}/customer/agentAppointment`,
    name: 'agentAppointment',
    meta: {
      title: '顾问预约',
      auth: true,
      cache: true,
    },
    component: _import('crm/customer/agentAppointment'),
  }
    
];
