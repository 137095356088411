const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'rm';

export default [
    // 短信模板页面
    {
        path: `${baseRoute}/system/sms`,
        name: 'sms',
        meta: {
            title: '短信格式配置',
            auth: true,
            cache: true,
        },
        component: _import('rm/system/sms'),
    },
]