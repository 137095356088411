const taskUrl = {
  task: {
    /** 任务分页列表 */
    getTaskPage: '/manage/task/getTaskPage',
    /** 新增任务 */
    addTask: '/manage/task/addTask',
    /** 更新任务 */
    updateTask: '/manage/task/updateTask',
    /** 更新任务状态 */
    updateTaskStatus: '/manage/task/updateTaskStatus',
    /** 执行任务 */
    executeTask: '/manage/task/executeTask',
    /** 获取最近10条任务执行日志 */
    getTaskLogList: '/manage/task/getTaskLogList',
  }
}

export default taskUrl
