// TODO 获取路由文件
const getRoutes = function (files) {
  let modules = {};

  files.keys().forEach(key => {
    modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
  });
  return modules;
};

// 导入路由文件
const files = require.context('./validate', false, /\.js$/);

export default getRoutes(files);
