import { ActionContext } from 'vuex';
import ElComponent from '@jyf/el-component';
import util from '@/libs/util';
import api from '@/api-new';
import url from '@/api-new/url';
import { imageDir } from '@/config/cloud';

const state = {
  // 华为云相关配置
  info: null,
};
export default {
  namespaced: true,
  state,
  getters: {
    info(state) {
      return state.info || {};
    },
  },
  mutations: {
    /** 设置华为云信息 */
    set(state, payload) {
      state.info = payload;
    },
  },
  actions: {
    /**
     * 初始化华为云数据
     * @param {*} param0
     */
    async init({ commit }) {
      // 没有数据，则发起请求
      let res = await api.GET_SIGN().catch(() => ({}));
      // const { cloudInfo, cloudUrl } = useCloud()
      // console.log(cloudInfo, cloudUrl)
      // let {
      //   baseUrl: baseUrlInfo = {},
      //   bucketName: bucketNameInfo = {},
      //   dfsService = [],
      // } = res || {};
      // let { dataValue: baseUrl } = baseUrlInfo;
      // let { dataValue: bucketName } = bucketNameInfo;
      // let uploadSelf = null;
      // let serverSelf = null;
      // if (!Array.isArray(dfsService)) {
      //   dfsService = [dfsService];
      // }
      // dfsService.forEach((ele) => {
      //   if (ele.dataKey === 'upload') {
      //     uploadSelf = ele.dataValue;
      //   } else if (ele.dataKey === 'read') {
      //     serverSelf = ele.dataValue;
      //   }
      // });

      // if (!baseUrl || !bucketName || !uploadSelf || !serverSelf) {
      //   util.log.danger('请配置华为云相关配置，否则组件库将无法正常使用！');
      // }

      let info = {
        upload: process.env.VUE_APP_CLOUD_UPLOAD,
        bucketName: process.env.VUE_APP_CLOUD_BUCKETNAME,
        upLoadKey: `${url.baseURL}${url.getSign}`,
        directory: imageDir,
        uploadSelf: process.env.VUE_APP_CLOUD_UPLOADSELF,
        serverSelf: process.env.VUE_APP_CLOUD_SERVERSELF,
      };
      commit('set', info);
      ElComponent.init(info);
    },
    /**
     * 确认是否已经加载了华为云数据
     * @returns boolean
     */
    async isLoaded({ state, dispatch }) {
      // 没有加载华为云数据则发起请求
      if (util.dataType.isEmptyObject(state.info)) {
        await dispatch('init');
      }
      return Promise.resolve(true);
    },
  },
};
