const sysManageUrl = {
  /** 获取用户拥有的功能权限码 */
  getUserPermissionCode: '/manage/system/user/getUserPermissionCode',
  /** 转移用户组织 */
  transferOrg: '/manage/system/user/transferOrg',
  /** 重新入职 */
  reEntry: '/manage/system/user/reEntry',
};

export default sysManageUrl;
