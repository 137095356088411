export default {
  inserted: (el, { value }, vnode) => {
    if (value) {
      vnode.context.$nextTick(async () => {
        let permission = vnode.context.$store.getters['d2admin/elePermission/getElePermission'];
        if (permission.length === 0) {
          permission = await vnode.context.$store.dispatch(
            'd2admin/db/get',
            {
              dbName: 'sys',
              path: 'elePermission.permission',
              defaultValue: {},
              user: true,
            },
            { root: true },
          );
        }
        if (permission && permission.length > 0) {
          let flag = false;
          permission.forEach(ele => {
            if (ele === value) {
              flag = true;
            }
          });
          if (!flag) {
            el.remove();
          }
        } else {
          el.remove();
        }
      });
    }
  },
};
