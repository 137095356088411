/** 请求状态码 */
const EnumCode = {
  /** 成功 */
  SUCCESS: '200',
  /** 无权限 */
  NO_AUTHORIZE: '403',
  /** 无路由 */
  NO_PAGE: '404',
  /** 用户未登录 */
  UNLOGIN: '10010',
  /** token 过期 */
  // TOKEN_EXPIRED = '10010',
  /** 无权限访问 */
  NO_PERMISSION: '10008',
  /** 需要使用验证码 */
  NEED_VALIDATE_CODE: '10005',
  /** 验证码为空 */
  VALIDATE_CODE_NULL: '10002',
  /** 验证码错误 */
  VALIDATE_CODE_ERR: '10004',
  /** 角色存在用户 */
  HAS_USER: '-2',
  /** 证件编码已存在，是否合并客户 */
  HAS_CUSTOMER: '9000',
}

export default EnumCode;
