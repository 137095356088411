//机构推介费
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    // 推介费计划
    {
        path: `${baseRoute}/commission/agency/plan`,
        name: 'commission-agency-plan',
        meta: {
            title: '机构推介费>计划',
            auth: true,
            cache: true,
        },
        component: _import('crm/commissionAgency/plan'),
    },
    // 推介费处理
    {
        path: `${baseRoute}/commission/agency/deal`,
        name: 'commission-agency-deal',
        meta: {
            title: '机构推介费>处理',
            auth: true,
            cache: true,
        },
        component: _import('crm/commissionAgency/deal'),
    },
    // 分公司审核
    {
        path: `${baseRoute}/commission/agency/org`,
        name: 'commission-agency-org',
        meta: {
            title: '机构推介费>分公司',
            auth: true,
            cache: true,
        },
        component: _import('crm/commissionAgency/org'),
    },
    // 财务审核
    {
        path: `${baseRoute}/commission/agency/finance`,
        name: 'commission-agency-finance',
        meta: {
            title: '机构推介费>财务',
            auth: true,
            cache: true,
        },
        component: _import('crm/commissionAgency/finance'),
    },
    // 财务总监审核
    {
        path: `${baseRoute}/commission/agency/financeManager`,
        name: 'commission-agency-financeManager',
        meta: {
            title: '机构推介费>财务总监',
            auth: true,
            cache: true,
        },
        component: _import('crm/commissionAgency/financeManager'),
    },
    // 汇总打印
    {
        path: `${baseRoute}/commission/agency/collect`,
        name: 'commission-agency-collect',
        meta: {
            title: '机构推介费>汇总打印',
            auth: true,
            cache: true,
        },
        component: _import('crm/commissionAgency/collect'),
    },
    // 结算确认
    {
        path: `${baseRoute}/commission/agency/count`,
        name: 'commission-agency-money',
        meta: {
            title: '机构推介费>结算',
            auth: true,
            cache: true,
        },
        component: _import('crm/commissionAgency/count'),
    },
    // 查询
    {
        path: `${baseRoute}/commission/agency/search`,
        name: 'commission-agency-search',
        meta: {
            title: '机构推介费>查询',
            auth: true,
            cache: true,
        },
        component: _import('crm/commissionAgency/search'),
    },
];