export default ({ request, url }) => ({
  //营销活动
  /** 新增 */
  MARKETING_ADD(data) {
    return request({
      url: url.marketingCampaign.manage.add,
      data,
    });
  },
  /** 更新 */
  MARKETING_UPDATE(data) {
    return request({
      url: url.marketingCampaign.manage.update,
      data,
    });
  },
  /** 更新状态 */
  MARKETING_UPDATESTATUS(data) {
    return request({
      url: url.marketingCampaign.manage.updateStatus,
      data,
    });
  },
  /** 删除 */
  MARKETING_DELETE(data) {
    return request({
      url: url.marketingCampaign.manage.delete,
      data,
    });
  },
  /** 获取详情 */
  MARKETING_GETINFOBYID(data) {
    return request({
      url: url.marketingCampaign.manage.getInfoById,
      data,
    });
  },
  /** 设置虚拟礼品状态 */
  MARKETING_UPDATEVIRTUALGIFT(data) {
    return request({
      url: url.marketingCampaign.manage.updateVirtualGiftStatus,
      data,
    });
  },
  /** 营销活动列表 */
  MARKETING_GETMARKETINGPAGE(data) {
    return request({
      url: url.marketingCampaign.manage.getMarketingActivityPage,
      data,
    });
  },
  /** 营销活动详情列表 */
  MARKETING_GETMARKETINGDETAILSPAGE(data) {
    return request({
      url: url.marketingCampaign.manage.getMarketingActivityDetailsPage,
      data,
    });
  },
  // 虚拟礼品
  /** 新增 */
  MARKETING_VIRTUALGIFTADD(data) {
    return request({
      url: url.marketingCampaign.virtualGift.addVirtualGift,
      data,
    });
  },
  /** 获取列表 */
  MARKETING_VIRTUALGIFTLIST(data) {
    return request({
      url: url.marketingCampaign.virtualGift.getList,
      data,
    });
  },
  /** 删除 */
  MARKETING_VIRTUALGIFTDELETE(data) {
    return request({
      url: url.marketingCampaign.virtualGift.delete,
      data,
    });
  },
  /** 导入券码 */
  MARKETING_VIRTUALGIFTCODE(data) {
    return request({
      url: url.marketingCampaign.virtualGift.importCouponCode,
      data,
      responseType: 'arraybuffer',
    });
  },

});
