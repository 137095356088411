export default ({ request, url }) => ({
  /** 广告位列表 */
  GET_ADVERTISE_LOCATION(data) {
    return request({
      url: url.advertising.getAdvertiseLocation,
      data
    }).then((res) => {
      return res
    });
  },
  /** 新增广告位 */
  ADD_ADVERTISE_LOCATION(data) {
    return request({
      url: url.advertising.addAdvertiseLocation,
      data
    }).then((res) => {
      return res
    });
  },
  /** 广告位详情 */
  GET_ADVERTISE_LOCATION_DETAIL(data) {
    return request({
      url: url.advertising.advertiseDetailLocation,
      data
    }).then((res) => {
      return res
    });
  },
  /** 删除广告位 */
  DELETE_ADVERTISE_LOCATION_DETAIL(data) {
    return request({
      url: url.advertising.deleteAdvertiseLocation,
      data
    }).then((res) => {
      return res
    });
  },
  /** 新增广告 */
  ADD_ADVERTISE_LIST(data) {
    return request({
      url: url.advertising.addAdvertise,
      data
    }).then((res) => {
      return res
    });
  },
  /** 广告详情 */
  GET_ADVERTISE_DETAIL(data) {
    return request({
      url: url.advertising.advertiseDetail,
      data
    }).then((res) => {
      return res
    });
  },
  /** 删除广告 */
  DELETE_ADVERTISE_DETAIL(data) {
    return request({
      url: url.advertising.deleteAdvertise,
      data
    }).then((res) => {
      return res
    });
  },
})