export default ({ request, url }) => ({
  /** 获取基本配置信息 */
  GET_SYS_CONFIG_BASIC() {
    return request({
      url: url.getPlatformBaseInfo,
    });
  },
  /** 更新基本配置信息 */
  SYS_CONFIG_BASIC_UPDATE(data) {
    return request({
      url: url.addOrUpdatePlatformBaseInfo,
      data,
    });
  },
});
