const commonUrl = {
  /** 数据字典 */
  dictionary: '/manage/enum/getByCode',
  /** 获取签名 */
  getSign: '/manage/obs/getSign',
  /** 枚举下拉列 */
  queryTypeList: '/alumniManage/base/enums/queryTypeList',
  /** 获取所有主办方 */
  getAllOrg: '/manage/rmOrg/getAllOrg',
  /** 获取所有组织职务 */
  getAllJob: '/alumniManage/job/getAllJob',
  /** 企业类别列表 */
  getAllCategory: '/alumniManage/enterpriseCategory/getAllCategory',
  /** 获取所有行业标签 */
  getAllIndustry: '/alumniManage/industry/getAllIndustry',
  /** 获取所有企业标签 */
  getEnterpriseLabel: '/alumniManage/userEnterpriseLabel/getEnterpriseLabel',
  /** 获取所有用户标签 */
  getUserLabel: '/alumniManage/userEnterpriseLabel/getUserLabel',
  /** 获取所有班级 */
  getAllGrade: '/alumniManage/grade/getAllGrade',
  /** 获取所有用户 */
  listUserBase: '/alumniManage/memberManage/listVipUserBase',
  /** 获取地址下拉列 */
  queryAddress: '/alumniManage/systemAddress/queryAddress',
  /** 待办消息 */
  todoUrl: '/manage/remind/list',
};

export default commonUrl;
