// 活动管理
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  // 财务总监
  {
    path: `${baseRoute}/activity/manage`,
    name: 'activtyManager',
    meta: {
      title: '活动和直播管理',
      auth: true,
      cache: true,
    },
    component: _import('crm/activity/manage/list'),
  },
  {
    path: `${baseRoute}/activity/manage/add`,
    name: 'activityAdd',
    meta: {
      title: '新增/修改活动',
      auth: true,
      cache: true,
    },
    component: _import('crm/activity/manage/add'),
  },
  {
    path: `${baseRoute}/activity/category`,
    name: 'activityCategory',
    meta: {
      title: '活动和直播分类',
      auth: true,
      cache: true,
    },
    component: _import('crm/activity/category'),
  },
  {
    path: `${baseRoute}/activity/evaluation/template`,
    name: 'activityTemplate',
    meta: {
      title: '评价模板',
      auth: true,
      cache: true,
    },
    component: _import('crm/activity/evaluation'),
  },
  {
    path: `${baseRoute}/activity/audit`,
    name: 'activityAudit',
    meta: {
      title: '活动和直播审核',
      auth: true,
      cache: true,
    },
    component: _import('crm/activity/audit'),
  },
];
