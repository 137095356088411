export default ({ request, url }) => ({
  /** 获取系统角色列表 */
  GET_PERMISSION_CODE_LIST () {
    return request({
      url: url.getUserPermissionCode,
    });
  },
  /** 转移用户组织 */
  TRANSFERORG (data) {
    return request({
      url: url.transferOrg,
      data,
    });
  },
  /** 重新入职 */
  RE_ENTRY_RY (data) {
    return request({
      url: url.reEntry,
      data,
    });
  },
});
