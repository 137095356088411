//核算
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    // 申请
    {
        path: `${baseRoute}/count/new`,
        name: 'count-new',
        meta: {
            title: '核算>申请',
            auth: true,
            cache: true,
        },
        component: _import('crm/count/new'),
    },
    // 结算日期确认
    {
        path: `${baseRoute}/count/countDate`,
        name: 'count-count-date',
        meta: {
            title: '核算>结算日确认',
            auth: true,
            cache: true,
        },
        component: _import('crm/count/countDate'),
    },
    // 核算确认(对日到期)
    {
        path: `${baseRoute}/count/newSameDate`,
        name: 'count-new-samedate',
        meta: {
            title: '核算>对日申请',
            auth: true,
            cache: true,
        },
        component: _import('crm/count/newSameDate'),
    },
    // 核算确认(固定日到期)
    {
        path: `${baseRoute}/count/newFixedDate`,
        name: 'count-new-fixeddate',
        meta: {
            title: '核算>固定日申请',
            auth: true,
            cache: true,
        },
        component: _import('crm/count/newFixedDate'),
    },
    // 客服审核
    {
        path: `${baseRoute}/count/service`,
        name: 'count-service',
        meta: {
            title: '核算>客服审核',
            auth: true,
            cache: true,
        },
        component: _import('crm/count/service'),
    },
    // 结算审核
    {
        path: `${baseRoute}/count/count`,
        name: 'count-count',
        meta: {
            title: '核算>结算审核',
            auth: true,
            cache: true,
        },
        component: _import('crm/count/count'),
    },
    // 财务审核
    {
        path: `${baseRoute}/count/finance`,
        name: 'count-finance',
        meta: {
            title: '核算>财务复核',
            auth: true,
            cache: true,
        },
        component: _import('crm/count/finance'),
    },
    // 核算查询
    {
        path: `${baseRoute}/count/search`,
        name: 'count-search',
        meta: {
            title: '核算>查询',
            auth: true,
            cache: true,
        },
        component: _import('crm/count/search'),
    },
    // 合同结算
    {
        path: `${baseRoute}/count/settle`,
        name: 'count-settle',
        meta: {
            title: '核算>合同结算',
            auth: true,
            cache: true,
        },
        component: _import('crm/count/settle'),
    },
];