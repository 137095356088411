/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export default ({ request, url }) => ({
  GET_REVIEW_LOG(id) {
    return request({
      url: url.activity.manage.reviewList,
      data: { id },
    });
  },
  ADD_REGISTRY_FORM(data) {
    return request({
      url: url.activity.manage.addRegistryForm,
      data,
    });
  },
  GET_REGISTRY_FORM(id) {
    return request({
      url: url.activity.manage.getRegistryForm,
      data: { id },
    });
  },
  IS_KEFU() {
    return request({
      url: url.activity.manage.isKefu,
    });
  },
  /* 获取所属分类下拉列表 */
  GET_CLASSIFY_LIST(id) {
    return request({
      url: url.activity.classify.listAllActivityClassify,
      data: { id },
    }).then(res => {
      return res.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
  },
  /* 获取所有活动模版列表 */
  GET_TEMPLATE_LIST(id) {
    return request({
      url: url.activity.template.listAllTemplate,
      data: { id },
    }).then(res => {
      return res.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
  },
  /* 获取所有活动类型列表 */
  GET_TYPE_LIST(id) {
    return request({
      url: url.activity.type.listActivityType,
      data: { id },
    }).then(res => {
      return res.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
  },

  /** 获取客户等级 */
  GET_CUSTOMER_LEVEL() {
    return request({
      url: url.customer.level.getChooseList,
    });
  },

  /* 获取活动签到列表 */
  GET_SIGNED_LIST(id) {
    return request({
      url: url.activity.manage.listSigned,
      data: { id },
    });
  },
  /* 获取活动预约列表 */
  GET_APPOINTMENT_LIST(data) {
    return request({
      url: url.activity.manage.listAppointment,
      data: data,
    });
  },
  /* 活动评价列表 */
  GET_EVALRECORD_LIST(id) {
    return request({
      url: url.activity.manage.listEvalRecord,
      data: { id },
    });
  },
  /* 获取客户参与记录分页数据 */
  GET_PAGE_LIST(data) {
    return request({
      url: url.activity.manage.getPage,
      data: data,
    });
  },
  /* 获取活动客户参与情况导出数据 */
  GET_EXPORTDATA(data) {
    return request({
      url: url.activity.manage.getExportData,
      responseType: 'arraybuffer',
      data: data,
    });
  },

  /* 获取活动详情 */
  async GET_ACTIVITY_DETAIL(id) {
    let res = await request({
      url: url.activity.manage.getActivity,
      data: { id },
    });
    const requires = ['品牌设计', '邀请嘉宾', '需要雅御协助'];
    return (res = {
      ...res,
      time: `${res.startTime} ~ ${res.endTime}`,
      budget:
        res.budgetList && res.budgetList.length > 0
          ? res.budgetList.reduce((value, obj) => (value += obj.budgetAmount), 0)
          : '',
      isScoresText: res.isScores == 1 ? '是' : '否',
      isAppointmentText: res.isAppointment == 1 ? '是' : '否',
      appointmentTime:
        res.isAppointment == 1 ? `${res.appointmentStartTime} ~ ${res.appointmentEndTime}` : '',
      isSignInText: res.isSignIn == 1 ? '是' : '否',
      signInTime: res.isSignIn == 1 ? `${res.signInStartTime} ~ ${res.signInEndTime}` : '',
      activityRequires: res.activityRequire
        ? res.activityRequire
          .split(',')
          .map((item, index) => {
            console.log(requires[Number(item) - 1]);
            return requires[Number(item) - 1];
          })
          .join(',')
        : '',
      targetAmountText: res.targetAmount ? res.targetAmount + '万元' : '',
      // isScores: res.isScores == 1 ? '是' : '否',
    });
  },

  /* 活动修改状态 */
  UPDATE_STATUS_ACTIVITY(data) {
    return request({
      url: url.activity.manage.updateStatus,
      data,
    });
  },

  /** 活动删除 */
  DELETE_ACTIVITY(id) {
    return request({
      url: url.activity.manage.deleteById,
      data: { id },
    });
  },

  /** 活动数值排序 */
  MANAGE_UP_DATESORT(data) {
    return request({
      url: url.activity.manage.updateSort,
      data,
    });
  },

  /** 获取资讯分类树形列表 */
  async GET_CLASSIFY_TREE_LIST() {
    let res = await request({
      url: url.info.classify.getClassifyTreeList,
    });

    return res.map(item => {
      return {
        ...item,
        isFirst: true,
      };
    });
  },
  /* 活动分类新增或修改 */
  ADD_OR_UPDATE_CATEGORY(data) {
    return request({
      url: url.activity.classify.addOrUpdate,
      data,
    });
  },

  /* 活动分类修改状态 */
  UPDATE_STATUS_CATEGORY(data) {
    return request({
      url: url.activity.classify.updateStatus,
      data,
    });
  },
  /** 活动分类删除 */
  DELETE_CATEGORY(id) {
    return request({
      url: url.activity.classify.deleteById,
      data: { id },
    });
  },
  /** 活动分类修改排序值 */
  UPDATASORT_CATEGORY(data) {
    return request({
      url: url.activity.classify.updateSort,
      data,
    });
  },
  /* 活动模板新增或修改 */
  ADD_OR_UPDATE_TEMPLATE(data) {
    return request({
      url: url.activity.template.addOrUpdate,
      data,
    });
  },
  /* 活动模板修改状态 */
  UPDATE_STATUS_TEMPLATE(data) {
    return request({
      url: url.activity.template.updateStatus,
      data,
    });
  },
  /** 活动模板删除 */
  DELETE_TEMPLATE(id) {
    return request({
      url: url.activity.template.deleteById,
      data: { id },
    });
  },
  /** 活动模板修改排序值 */
  UPDATASORT_TEMPLATE(data) {
    return request({
      url: url.activity.template.updateSort,
      data,
    });
  },
  /** 审核或者重新审核 */
  MANAGE_AUDIT(data) {
    return request({
      url: url.activity.manage.audit,
      data,
    });
  },
});
