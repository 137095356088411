//礼品管理
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    // 礼品管理
    {
        path: `${baseRoute}/gift/gift`,
        name: 'gift-gift',
        meta: {
            title: '礼品>管理',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/gift'),
    },
    // 礼品申请
    {
        path: `${baseRoute}/gift/apply/new`,
        name: 'gift-apply-new',
        meta: {
            title: '礼品申请>申请',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/apply/new'),
    },
    // 客服审核
    {
        path: `${baseRoute}/gift/apply/service`,
        name: 'gift-apply-service',
        meta: {
            title: '礼品申请>客服',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/apply/service'),
    },
    // 财务审核
    {
        path: `${baseRoute}/gift/apply/finance`,
        name: 'gift-apply-finance',
        meta: {
            title: '礼品申请>财务',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/apply/finance'),
    },
    // 客服发货
    {
        path: `${baseRoute}/gift/apply/delivery`,
        name: 'gift-apply-delivery',
        meta: {
            title: '礼品申请>发货',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/apply/delivery'),
    },
    // 确认收货
    {
        path: `${baseRoute}/gift/apply/receive`,
        name: 'gift-apply-receive',
        meta: {
            title: '礼品申请>收货',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/apply/receive'),
    },
    // 礼品查询
    {
        path: `${baseRoute}/gift/apply/search`,
        name: 'gift-apply-search',
        meta: {
            title: '礼品申请>查询',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/apply/search'),
    },
    // 礼品查询(分公司)
    {
        path: `${baseRoute}/gift/apply/searchOrg`,
        name: 'gift-apply-searchOrg',
        meta: {
            title: '礼品申请>机构查询',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/apply/searchOrg'),
    },

    // 礼品申请(自费)
    {
        path: `${baseRoute}/gift/applySelf/new`,
        name: 'gift-applySelf-new',
        meta: {
            title: '礼品自费>申请',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/applySelf/new'),
    },
    // 客服审核(自费)
    {
        path: `${baseRoute}/gift/applySelf/service`,
        name: 'gift-applySelf-service',
        meta: {
            title: '礼品自费>客服',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/applySelf/service'),
    },
    // 财务审核(自费)
    {
        path: `${baseRoute}/gift/applySelf/finance`,
        name: 'gift-applySelf-finance',
        meta: {
            title: '礼品自费>财务',
            auth: true,
            cache: true,
        },
        component: _import('crm/gift/applySelf/finance'),
    },
];