// 营销活动
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    // 营销活动
    {
        path: `${baseRoute}/marketingCampaign/manage`,
        name: 'marketingCampaign',
        meta: {
            title: '营销活动列表',
            auth: true,
            cache: true,
        },
        component: _import('crm/marketingCampaign/manage/list'),
    },
    // 新增
    {
        path: `${baseRoute}/marketingCampaign/manage/add`,
        name: 'marketingCampaignAdd',
        meta: {
            title: '新增/修改',
            auth: true,
            cache: true,
        },
        component: _import('crm/marketingCampaign/manage/add'),
    },
    // 营销活动详情
    {
        path: `${baseRoute}/marketingCampaign/manage/details`,
        name: 'marketingCampaignDetails',
        meta: {
            title: '参与详情',
            auth: true,
            cache: true,
        },
        component: _import('crm/marketingCampaign/manage/details'),
    },
];
