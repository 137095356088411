import request from '@/plugin/axios'
import util from "@/libs/util.js";
import STORE from "@/maps/enum/store";
import { checkVerifyCode } from '@api/common/common'
import func from '@/maps/url/common/account'

/**
 * 获取当前登入用户信息 
 * @export
 */
export function getLoginUserInfo() {
    let personuuid = util.cookies.get("uuid"); //当前用户uuid
    let jsonUserInfo = JSON.parse(localStorage.getItem(STORE.CRM_INFO));
    let loginUser = jsonUserInfo.sys.user[personuuid].user.info; //localStorage中可能保存了多个历史用户，取当前用户
    return loginUser;
}

// TODO 登录
export function AccountLogin(params) {
    return new Promise(async(resolve, reject) => {
        let { verifyId = '', verifyCode = '', username, password } = params;
        let flag = await checkVerifyCode({ verifyId, verifyCode, }); // 验证码是否校验通过

        // 验证失败
        if (!flag) {
            reject(new Error('验证码错误'))
            return
        }
        request.post(func.login, {
            body: {
                loginName: username,
                password,
            }
        }).then(res => {
            let { data = {} } = res;
            resolve(data)
        }).catch(e => {
            reject(new Error('账号或密码错误'))
        })
    })
}