//存储key
export default {
    RM_MENU: 'jq-rm-menu', //用户菜单
    CRM_PERSONUUID: 'jq-crm-uuid', //用户Id
    CRM_TOKEN: 'jq-crm-token', //用户Id
    CRM_INFO: 'jq-crm-info', //用户信息        
    CRM_CONFIG: 'jq-crm-config', //用户配置
    CRM_ORG: 'jq-crm-org', //用户机构
    CRM_PRODUCT: 'jq-crm-product', //用户产品
    CRM_REMIND: 'jq-crm-remind', //消息提醒
}