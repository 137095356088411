//付息
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    // 申请
    {
        path: `${baseRoute}/interest/new`,
        name: 'interest-new',
        meta: {
            title: '付息>申请',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/new'),
    },
    // 申请(对日到期)
    {
        path: `${baseRoute}/interest/newSameDate`,
        name: 'interest-newSameDate',
        meta: {
            title: '付息>付息确认（对日）',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/newSameDate'),
    },
    // 申请(固定日到期)
    {
        path: `${baseRoute}/interest/newFixedDate`,
        name: 'interest-newFixedDate',
        meta: {
            title: '付息>付息确认（固定日）',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/newFixedDate'),
    },
    // 客服审核
    {
        path: `${baseRoute}/interest/service`,
        name: 'interest-service',
        meta: {
            title: '付息>客服审核',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/service'),
    },
    // 结算审核
    /*结算和财务合并
    {
        path: `${baseRoute}/interest/count`,
        name: 'interest-count',
        meta: {
            title: '付息>结算审核',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/count'),
    },
    */
    // 财务审核
    {
        path: `${baseRoute}/interest/finance`,
        name: 'interest-finance',
        meta: {
            title: '付息>结算审核',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/finance'),
    },
    // 财务总监审核
    {
        path: `${baseRoute}/interest/financeManager`,
        name: 'interest-financeManager',
        meta: {
            title: '付息>财务复核',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/financeManager'),
    },
    // 打印
    {
        path: `${baseRoute}/interest/print`,
        name: 'interest-print',
        meta: {
            title: '付息>打印',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/print'),
    },
    // 集团资金确认
    {
        path: `${baseRoute}/interest/money`,
        name: 'interest-money',
        meta: {
            title: '付息>集团资金确认',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/money'),
    },
    // 查询
    {
        path: `${baseRoute}/interest/search`,
        name: 'interest-search',
        meta: {
            title: '付息>查询',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/search'),
    },
    // 帐单
    {
        path: `${baseRoute}/interest/bill`,
        name: 'interest-bill',
        meta: {
            title: '付息>帐单',
            auth: true,
            cache: true,
        },
        component: _import('crm/interest/bill'),
    },
];