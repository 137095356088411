// 任务调度管理
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  {
    path: `${baseRoute}/merchantManagement/merchantManagement`,
    name: 'merchantManagement',
    meta: {
      title: '商户管理',
      auth: true,
      cache: true,
    },
    component: _import('crm/merchantManagement/merchantManagement'),
  },
  {
    path: `${baseRoute}/merchantManagement/receivingPaymentManagement`,
    name: 'receivingPaymentManagement',
    meta: {
      title: '收付信息',
      auth: true,
      cache: true,
    },
    component: _import('crm/merchantManagement/receivingPaymentManagement'),
  }
]
