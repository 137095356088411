//线下纸合同
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    // 申请
    {
        path: `${baseRoute}/paper/express/new`,
        name: 'paper-express-new',
        meta: {
            title: '纸合同>盖章申请',
            auth: true,
            cache: true,
        },
        component: _import('crm/paper/express/new'),
    },
    // 客服审核
    {
        path: `${baseRoute}/paper/service`,
        name: 'paper-service',
        meta: {
            title: '纸合同>客服审核',
            auth: true,
            cache: true,
        },
        component: _import('crm/paper/service'),
    },
    // 合同寄回
    {
        path: `${baseRoute}/paper/express/back`,
        name: 'paper-express-back',
        meta: {
            title: '纸合同>合同寄回',
            auth: true,
            cache: true,
        },
        component: _import('crm/paper/express/back'),
    },
    // 合同签收
    {
        path: `${baseRoute}/paper/express/receive`,
        name: 'paper-express-receive',
        meta: {
            title: '纸合同>合同签收',
            auth: true,
            cache: true,
        },
        component: _import('crm/paper/express/receive'),
    },
    // 快递编辑
    {
        path: `${baseRoute}/paper/express/edit`,
        name: 'paper-express-edit',
        meta: {
            title: '纸合同>快递编辑',
            auth: true,
            cache: true,
        },
        component: _import('crm/paper/express/edit'),
    },
    // 快递查询
    {
        path: `${baseRoute}/paper/express/search`,
        name: 'paper-express-search',
        meta: {
            title: '纸合同>快递查询',
            auth: true,
            cache: true,
        },
        component: _import('crm/paper/express/search'),
    },
    // 合同归档
    {
        path: `${baseRoute}/paper/box/archive`,
        name: 'paper-box-archive',
        meta: {
            title: '纸合同>合同归档',
            auth: true,
            cache: true,
        },
        component: _import('crm/paper/box/archive'),
    },
    // 合同柜号查询
    {
        path: `${baseRoute}/paper/box/search`,
        name: 'paper-box-search',
        meta: {
            title: '纸合同>合同归档查询',
            auth: true,
            cache: true,
        },
        component: _import('crm/paper/box/search'),
    },
];