import { useStore } from '@/store';
import { computed } from '@vue/composition-api';

export default function useUser() {
  const store = useStore();

  /**
   * 设置用户信息
   * @param user 用户信息
   * @returns Promise<user>
   */
  const setUser = (user) => {
    return store.dispatch('d2admin/user/set', user, { root: true });
  };

  /**
   * 用户信息
   */
  const userInfo = computed(() => {
    return store.getters['d2admin/user/get'];
  });

  return {
    /** 设置用户信息 */
    setUser,
    /** 用户信息 */
    userInfo,
  };
}
