const sysApiMenu = {
  /** 所有菜单 */
  sysMenuList: '/alumniManage/systemMenu/queryMenuAllList',
  /** 删除菜单 */
  sysMenuDelete: '/alumniManage/systemMenu/delMenu',
  /** 菜单详情 */
  sysMenuDetail: '/alumniManage/systemMenu/queryMenuById',
  /** 菜单新增/编辑 */
  sysMenuAddOrUpdate: '/alumniManage/systemMenu/addorupdateMenu',
  /** 菜单拖拽 */
  sysMenuDrag: '/alumniManage/systemMenu/dragMenu',
};

export default sysApiMenu;
