import ElComponent from '@jyf/el-component';
export default {
  namespaced: true,
  state: {
    // 元素权限
    permission: []
  },
  getters: {
    getElePermission (state) {
      return state.permission
    }
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} context
     * @param {*} permission permission
     */
    async set ({ state, dispatch }, permission) {
      // store 赋值
      state.permission = permission
      ElComponent.init({
        permission: state.permission,
      });
      // 持久化
      await dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'elePermission.permission',
        value: permission,
        user: true
      }, { root: true })
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} context
     */
    async load ({ state, dispatch }) {
      // store 赋值
      state.permission = await dispatch('d2admin/db/get', {
        dbName: 'sys',
        path: 'elePermission.permission',
        defaultValue: {},
        user: true
      }, { root: true });
      ElComponent.init({
        permission: state.permission,
      });
    }
  }
}
