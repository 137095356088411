// TODO 校验是否为邮箱
export default function (rule, value, callback) {
  const { required = false } = rule;
  const reg = /^([\.a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;

  if (required && !value) {
    callback(new Error('邮箱不能为空'));
  } else if (!reg.test(value)) {
    callback(new Error('请输入有效的邮箱地址'));
  } else {
    callback();
  }
}
