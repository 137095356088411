export default ({ request, url }) => ({
  /* 所有菜单 */
  SYS_MENU_LIST(data = {}) {
    return request({
      url: url.sysMenuList,
      data,
    }).then((res) => {
      res.map((item) => {
        item.value = item.id;
        item.label = item.title;
        return item;
      });
      return res;
    });
  },
  /* 菜单删除 */
  SYS_MENU_DELETE(data = {}) {
    return request({
      url: url.sysMenuDelete,
      data,
    }).then((res) => {
      return res;
    });
  },
  /* 菜单详情 */
  SYS_MENU_DETAIL(data = {}) {
    return request({
      url: url.sysMenuDetail,
      data,
    }).then((res) => {
      if (res.systemApiId) {
        res.systemApiId = res.systemApiId.split(';').map(Number);
      }
      return {
        id: res.id,
        pid: res.pid,
        title: res.title,
        resourceType: res.resourceType,
        perms: res.perms,
        permissionCode: res.permissionCode,
        systemApiId: res.systemApiId,
        status: res.status,
        sort: res.sort,
      };
    });
  },
  /* 菜单新增/编辑 */
  SYS_MENU_ADD_OR_UPDATE(data = {}) {
    return request({
      url: url.sysMenuAddOrUpdate,
      data,
    }).then((res) => {
      return res;
    });
  },
  /* 菜单拖拽 */
  SYS_MENU_DRAG(data = {}) {
    return request({
      url: url.sysMenuDrag,
      data,
    }).then((res) => {
      return res;
    });
  },
});
