/**
 * 对数字做加法计算，接受多个值，测试值(0.1 + 0.2)
 * @param arg 传入的参数列表(数组)
 * @returns {number} 计算后的合计值
 */
export const add = function (...arg) {
  if (arg.length === 1) {
    return arg[0]
  }
  // 获取小数位长度数组
  let decLens = arg.reduce((arr, item) => {
    arr.push((`${item}`.split('.')[1] || '').length)
    return arr
  }, [])
  let m = Math.max.apply(null, decLens) // 小数位最长长度
  let total = arg.reduce((total, item, index) => {
    // 将数字转为整数，用最长小数位长度减去当前数字的小数位长度乘以去掉小数点的当前数字
    total += Number(`${item}`.replace('.', '')) * Math.pow(10, m - decLens[index])
    return total
  }, 0)
  return total / Math.pow(10, m)
}

/**
 * 对数字做减法计算，接受多个值，测试值(1.5 + 1.2)
 * @param minuend 被减数
 * @param arg 减数列表(数组)
 * @returns {number}
 */
export const sub = function (minuend, ...arg) {
  let subtraction = add(...arg) // 获取减数的和
  let mLen = (`${minuend}`.split('.')[1] || '').length // 被除数小数位长度
  let sLen = (`${subtraction}`.split('.')[1] || '').length; // 减数小数位长度
  let m = Math.max.call(null, mLen, sLen) // 小数位最长长度
  minuend = Number(`${minuend}`.replace('.', '')) * Math.pow(10, m - mLen)
  subtraction = Number(`${subtraction}`.replace('.', '')) * Math.pow(10, m - sLen)
  return (minuend - subtraction) / Math.pow(10, m)
}

/**
 * 对数字做乘法，接受多个值，测试值(35.41 * 100)
 * @param arg 乘数列表(数组)
 * @returns {number}
 */
export const mul = function (...arg) {
  if (arg.length === 1) {
    return arg[0]
  }
  let m = 0 // 小数位的和
  let total = arg.reduce((total, item) => {
    m += (`${item}`.split('.')[1] || '').length
    return total * parseFloat(`${item}`.replace('.', ''))
  }, 1)
  return total / Math.pow(10, m)
}

/**
 * 对数字做除法，接受多个值，测试值(0.3 / 0.1)
 * @param divisor 被除数
 * @param arg 除数列表
 * @returns {number}
 */
export const div = function (divisor, ...arg) {
  let product = mul(arg)
  let dLen = (`${divisor}`.split('.')[1] || '').length
  let pLen = (`${product}`.split('.')[1] || '').length
  divisor = Number(`${divisor}`.replace('.', ''))
  product = Number(`${product}`.replace('.', ''))
  return divisor / product * Math.pow(10, pLen - dLen)
}

/**
 * 保留小数位
 * @param val 处理值
 * @param {number} length 保留位数，默认2位
 * @param {boolean} isKeep 是否保留小数位长度，即传入0时是否展示0.00
 * @returns {string}
 */
export const toFixed = function (val, length = 2, isKeep = true) {
  if (val === undefined || !/^-?\d*\.?\d*$/.test(val)) {
    console.error('the value can not change to number')
    return ''
  }

  let [num = '', dec = ''] = `${val}`.split('.'); // 获取整数值和小数值
  // dec = dec.padEnd(length, '0'); // 将0 补全
  if (dec.length < length || isKeep) {
    dec = `${dec}${'0'.repeat(length - dec.length)}` // 将0 补全
  }
  // 小数位长度多于要保留的长度
  if (dec.length !== length) {
    dec = `${dec.substr(0, length)}.${dec.substr(length)}` // 给要保留的位数后加小数点
    // dec = `${Math.round(dec)}`.padStart(length, '0'); // 往前面补0，处理类似001这种情况
    dec = `${Math.round(parseFloat(dec))}` // 四舍五入
    if (dec.length < length) {
      dec = `${'0'.repeat(length - dec.length)}${dec}` // 往前面补0，处理类似001这种情况
    }
  }

  return `${num}.${dec}`
}

export default {
  add,
  sub,
  mul,
  div,
  toFixed,
}
