import { SYSTEM_FLAG } from '@/config';
import { computed } from '@vue/composition-api';

const UUID_KEY = `${SYSTEM_FLAG}-uuid`;
/** 使用用户唯一标识 */
export default function useUUID() {
  /**
   * 设置用户唯一标识
   * @param uuid 唯一标识值
   * @returns Promise<uuid>
   */
  const setUUID = (uuid) => {
    window.localStorage.setItem(UUID_KEY, uuid);
    return Promise.resolve(uuid);
  };

  /** 用户唯一标识  */
  const uuid = computed(() => {
    return window.localStorage.getItem(UUID_KEY) || 'ghost-uuid';
  });

  return {
    setUUID,
    uuid,
  };
}
