//到期与清算
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    // 到期查询
    {
        path: `${baseRoute}/budget/budget`,
        name: 'budget-money',
        meta: {
            title: '预算>资金',
            auth: true,
            cache: true,
        },
        component: _import('crm/budget/budget'),
    },
];