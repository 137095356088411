/**
 * 团队
 */
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);
const baseRoute = 'rm';

export default [
    // 申请
    {
        path: `${baseRoute}/system/system/team/apply`,
        name: 'team-apply',
        meta: {
            title: '团队申请',
            auth: true,
            cache: true,
        },
        component: _import('rm/system/system/team/apply'),
    },
    // 审核
    {
        path: `${baseRoute}/system/system/team/audit`,
        name: 'team-audit',
        meta: {
            title: '团队审核',
            auth: true,
            cache: true,
        },
        component: _import('rm/system/system/team/audit'),
    },
    // 查询  
    {
        path: `${baseRoute}/system/system/team/search`,
        name: 'team-search',
        meta: {
            title: '团队查询',
            auth: true,
            cache: true,
        },
        component: _import('rm/system/system/team/search'),
    },

]