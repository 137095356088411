import { useStore } from '@/store';
import { computed } from '@vue/composition-api';

export default function useToken() {
  const store = useStore();

  /**
   * 设置 token
   * @param token token 信息
   * @returns Promise<token>
   */
  const setToken = (token) => {
    return store.dispatch('d2admin/token/set', token, { root: true });
  };

  /**
   * 获取 token
   * @returns token
   */
  const getToken = () => {
    return store.getters['d2admin/token/get'];
  };

  /** token 值 */
  const token = computed(() => {
    const tokenInfo = getToken();

    return tokenInfo.access_token || '';
  });

  return {
    setToken,
    getToken,
    token,
  };
}
