export default ({ request, url }) => ({
  /** 新增任务 */
  ADD_TASK(data) {
    return request({
      url: url.task.addTask,
      data
    });
  },
  /** 更新任务 */
  UPDATE_TASK(data) {
    return request({
      url: url.task.updateTask,
      data
    });
  },
  /** 更新任务状态 */
  UPDATE_TASK_STATUS(data) {
    return request({
      url: url.task.updateTaskStatus,
      data
    });
  },
  /** 执行任务 */
  EXECUTE_TASK(data) {
    return request({
      url: url.task.executeTask,
      data
    });
  },
  /** 获取最近10条任务执行日志 */
  TASK_LOG(data) {
    return request({
      url: url.task.getTaskLogList,
      data
    }).then((res) => {
      return res
    });
  }
})
