const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    // 合同统计
    {
        path: `${baseRoute}/report/contract/total`,
        name: 'contract-total',
        meta: {
            title: '合同统计',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/contract/total'),
    },
    // 合同审核统计
    {
        path: `${baseRoute}/report/contract/audit`,
        name: 'contract-audit',
        meta: {
            title: '合同审核统计',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/contract/audit'),
    },
    // 财富月入金
    {
        path: `${baseRoute}/report/orgjf/buy`,
        name: 'orgjf-buy',
        meta: {
            title: '财富月入金',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/orgjf/buy'),
    },
    // 直营机构入金
    {
        path: `${baseRoute}/report/org/buy`,
        name: 'org-buy',
        meta: {
            title: '直营机构入金',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/org/buy'),
    },
    // 合同逾期录入统计
    {
        path: `${baseRoute}/report/contract/overdue`,
        name: 'contract-overdue',
        meta: {
            title: '合同逾期录入统计',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/contract/overdue'),
    },
    // 直营业绩追踪表
    {
        path: `${baseRoute}/report/org/money`,
        name: 'org-money',
        meta: {
            title: '直营业绩追踪表',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/org/money'),
    },
    // 直营业绩分析报表
    {
        path: `${baseRoute}/report/org/analyze`,
        name: 'org-analyze',
        meta: {
            title: '直营业绩分析报表',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/org/analyze'),
    },
    // 资金流水汇总报表
    {
        path: `${baseRoute}/report/money/inout`,
        name: 'money-inout',
        meta: {
            title: '资金流水汇总报表',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/money/inout'),
    },
    // 审核统计
    {
        path: `${baseRoute}/report/audit/audit`,
        name: 'audit',
        meta: {
            title: '审核统计',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/audit/audit'),
    },
    // 业务汇总报表
    {
        path: `${baseRoute}/report/business`,
        name: 'business',
        meta: {
            title: '业务汇总报表',
            auth: true,
            cache: true,
        },
        component: _import('crm/report/business'),
    },
]