export default ({ request, url }) => ({
  /* 可选系统菜单api列表 */
  SYS_API_OPTION_LIST(data = {}) {
    return request({
      url: url.sysApiOptionList,
      data,
    }).then((res) => {
      res.map((item) => {
        item.label = item.apiName;
        item.value = item.id;
        return item;
      });
      return res;
    });
  },
  /* 新增/编辑菜单api */
  SYS_API_ADD_OR_UPDATE(data = {}) {
    return request({
      url: url.sysApiAddOrUpdate,
      data,
    }).then(() => {});
  },
  /*  菜单api详情 */
  SYS_API_DETAIL(id) {
    return request({
      url: url.sysApiDetail,
      data: { id },
    }).then((res) => {
      return res;
    });
  },
  /* api 删除 */
  SYS_API_DELETE(data = {}) {
    return request({
      url: url.sysApiDelete,
      data,
    }).then((res) => {
      return res;
    });
  },
});
