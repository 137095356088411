import cookies from './util.cookies'
import db from './util.db'
import log from './util.log'
import calc from './util.calc'
import validate from './util.validate'
import dataType from './util.type'

const util = {
    cookies,
    db,
    log,
    calc,
    validate,
    dataType
}

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = function(titleText) {
        const processTitle = process.env.VUE_APP_TITLE || 'D2Admin'
        window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
}

/**
 * @description 打开新页面
 * @param {String} url 地址
 */
util.open = function (url) {
  var a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.setAttribute('id', 'd2admin-link-temp')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(document.getElementById('d2admin-link-temp'))
}

// TODO 函数节流
util.throttle = function (fn, gapTime = 1000) {
  let _lastTime = null // 触发前点击的时间

  // 返回新的函数
  return function () {
    let _nowTime = +new Date()
    if (_nowTime - _lastTime > gapTime || !_lastTime) {
      fn.apply(this, arguments)   //将this和参数传给原函数
      _lastTime = _nowTime
    }
  }
}

// TODO 下载
util.download = function (content, fileName, type = 'blob') {
  let blob = new Blob([content]);
  let elink = document.createElement('a');
  elink.download = fileName;
  elink.style.display = 'none';
  elink.href = URL.createObjectURL(blob);

  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
}

// TODO 深拷贝，传入拷贝对象
function deepClone (target) {
  let type = Object.prototype.toString.call(target);
  let clone = target;

  switch (type) {
    // 对象类型
    case '[object Object]':
      clone = {};
      Object.entries(target).forEach(([key, value]) => {
        clone[key] = deepClone(value)
      });
      break;
    // 数组类型
    case '[object Array]':
      clone = [];
      clone = target.map(item => {
        return deepClone(item)
      });
      break;
    default:
      break
  }
  return clone
}

util.deepClone = deepClone;

export default util