const sysApiUrl = {
  /** 菜单api列表 */
  sysApiList: '/alumniManage/systemApi/systemApiList',
  /** 可选系统菜单api列表 */
  sysApiOptionList: '/alumniManage/systemApi/optionalSystemApiList',
  /** 新增/编辑菜单api */
  sysApiAddOrUpdate: '/alumniManage/systemApi/addEditApi',
  /** 菜单api详情 */
  sysApiDetail: '/alumniManage/systemApi/apiDetail',
  /** api 删除  */
  sysApiDelete: '/alumniManage/systemApi/deleteBatchApi',
};

export default sysApiUrl;
