/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export default ({ request, url }) => ({
  /** 获取资讯列表 */
  // GET_INFO_LIST(data) {
  //   return request({
  //     url: url.info.document.infoList,
  //     data,
  //   })
  // },
  /* 获取所属分类下拉列表 */
  GET_CHOOSE_LIST(id) {
    return request({
      url: url.info.classify.getChooseList,
      data: { id },
    }).then(res => {
      return res;
    });
  },
  GET_VIDEO(data) {
    return request({
      url: url.info.material.getReviewPageList,
      data,
    }).then(res => {
      return res;
    });
  },
  ADD_VIDEO(data) {
    return request({
      url: url.info.document.addVideo,
      data,
    }).then(res => {
      return res;
    });
  },
  /* 阅读记录 */
  READ_INFO(data) {
    return request({
      url: url.info.document.readInfo,
      data,
    }).then(res => {
      return res;
    });
  },
  /* 新建素材 */
  ADD_INFO(data) {
    return request({
      url: url.info.material.addInfo,
      data,
    }).then(res => {
      return res;
    });
  },
  /* 更新素材状态 */
  UPDATE_MATERIAL(data) {
    return request({
      url: url.info.material.updateStatus,
      data,
    }).then(res => {
      return res;
    });
  },
  /* 获取素材详情 */
  GET_INFO(data) {
    return request({
      url: url.info.material.getInfo,
      data,
    }).then(res => {
      return res;
    });
  },
  /* 获取所有一级咨讯分类 */
  GET_ALL_STATUS_CHOOSE_LIST(data) {
    return request({
      url: url.info.classify.listOneLevel,
      data,
    }).then(res => {
      return res.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
  },
  /* 获取底部信息分页列表 */
  GET_BOTTOM_PAGE_LIST(data) {
    return request({
      url: url.info.bottom.getBottomPageList,
      data,
    });
  },
  /** 获取资讯分类树形列表 */
  async GET_CLASSIFY_TREE_LIST() {
    let res = await request({
      url: url.info.classify.getClassifyTreeList,
    });

    return res.map(item => {
      return {
        ...item,
        isFirst: true,
      };
    });
  },
  /* 新增资讯分类 */
  ADD_CLASSIFY(data) {
    return request({
      url: url.info.classify.addClassify,
      data,
    });
  },
  /* 更新资讯分类 */
  UPDATE_CLASSIFY(data) {
    return request({
      url: url.info.classify.updateClassify,
      data,
    });
  },
  /* 更新资讯分类状态 */
  UPDATE_STATUS_CLASSIFY(data) {
    return request({
      url: url.info.classify.updateStatus,
      data,
    });
  },
  /* 获取底部信息下拉选择数据 */
  GET_BOTTOM_CHOOSE_LIST() {
    return request({
      url: url.info.document.getBottomChooseList,
    }).then(res => {
      return res.list.map(item => {
        return {
          value: item.id,
          label: item.title,
        };
      });
    });
  },
  /** 审核或者重新审核 */
  REVIEW(data) {
    return request({
      url: url.info.document.review,
      data,
    });
  },
  /* 更新列表排序值 */
  GET_DOCUMENT_SORT(data) {
    return request({
      url: url.info.document.sort,
      data,
    });
  },
  /* 更新底部信息排序值 */
  UPDATE_BOTTOM_SORT(data) {
    return request({
      url: url.info.bottom.updateSort,
      data,
    });
  },

  /* 每日金句 */
  // 删除金句
  DELETE_SENTENCE(data) {
    return request({
      url: url.info.dailySentence.batchDelete,
      data,
    });
  },
  // 更新金句状态
  UPDATE_SENTENCE_STATUS(data) {
    return request({
      url: url.info.dailySentence.updateStatus,
      data,
    });
  },
  // 更新金句排序值
  UPDATE_SENTENCE_SORT(data) {
    return request({
      url: url.info.dailySentence.updateSort,
      data,
    });
  },
  // 批量增加金句
  BATCH_SENTENCE_ADD(data) {
    return request({
      url: url.info.dailySentence.batchAdd,
      data,
    });
  },
  // 获取金句详情
  GET_SENTENCE_INFO(data) {
    return request({
      url: url.info.dailySentence.getInfo,
      data,
    });
  },
  // 修改金句
  UPDATE_SENTENCE_INFO(data) {
    return request({
      url: url.info.dailySentence.update,
      data,
    });
  },
});
