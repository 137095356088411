export default ({ request, url }) => ({
  /* 组织架构列表 */
  LIST_ORGANIZATION() {
    return request({
      url: url.orgManager.framework.listOrganization,
    }).then((res) => {
      let children = res.list.map((item) => {
        return {
          ...item,
          label: item.name
        }
      }) || []
      /* 配合后端做写死处理，等待后端有时间处理后再作调整 */
      let list = [
        {
          children,
          id: 0,
          isAudit: null,
          isTop: null,
          levelParentId: null,
          name: '创融会',
          orgType: null,
          parentId: null,
          status: null,
          bindNum: 0,
          isMock: true // 判断是否为写死的假数据,是则只有新增组织按钮
        }
      ];
      return list;
    })
  },
  /* 组织架构新增或修改 */
  ADD_OR_UPDATE_ORGANIZATION(data) {
    return request({
      url: url.orgManager.framework.addOrUpdateOrganization,
      data
    });
  },
  /* 组织架构组织详情 */
  GET_DETAIL_ORGANIZATION(id) {
    return request({
      url: url.orgManager.framework.getDetailOrganization,
      data:{id}
    });
  },
    /* 顶级组织绑定人数 */
  GET_TOP_BINDNUM() {
    return request({
      url: url.orgManager.framework.getTopBindNum
    });
  },
  /* 组织架构状态修改 */
  UPDATE_STATUS_ORGANIZATION(data) {
    return request({
      url: url.orgManager.framework.updateStatus,
      data
    });
  },
  /* 组织架构删除 */
  DELETE_ORGANIZATION(data) {
    return request({
      url: url.orgManager.framework.deleteOrganization,
      data
    });
  },
  /* 组织架构审核状态修改 */
  UPDATE_IS_AUDIT_ORGANIZATION(data) {
    return request({
      url: url.orgManager.framework.updateIsAudit,
      data
    });
  },
  /* 组织架构置顶修改 */
  UPDATE_IS_TOP_ORGANIZATION(data) {
    return request({
      url: url.orgManager.framework.updateIsTop,
      data
    });
  },
  /** 组织成员头部统计数据 */
  STATISTICAL_DATA_ORG_MEMBER() {
    return request({
      url: url.orgManager.member.statisticalData,
    });
  },
  /** 组织成员新增或修改 */
  ADD_OR_UPDATE_ORGANIZATION_MEMBER(data) {
    return request({
      url: url.orgManager.member.addOrUpdateOrganizationMember,
      data
    });
  },
  /** 组织成员审核 */
  APPLY_ORGANIZATION_USER(data) {
    return request({
      url: url.orgManager.member.applyOrganizationUser,
      data
    });
  },
  /** 删除组织成员 */
  DELETE_ORGANIZATION_MEMBER(data) {
    return request({
      url: url.orgManager.member.deleteOrganizationMember,
      data
    });
  },
  /** 删除组织成员申请 */
  DELETE_ORGANIZATION_USER(data) {
    return request({
      url: url.orgManager.member.deleteOrganizationUser,
      data
    });
  },
  /** 组织成员导出 */
  EXPORT_ORGANIZATION_MEMBER(data) {
    return request({
      url: url.orgManager.member.exportOrganizationMember,
      data,
      responseType:'arraybuffer'
    });
  },
  /** 修改组织成员排序值 */
  UPDATA_SORT_ORGANIZATION(data) {
    return request({
      url: url.orgManager.member.updataSort,
      data
    });
  },
  /** 获取职务类型 */
  GET_ALL_JOB_TYPE(data) {
    return request({
      url: url.orgManager.job.getAllJobType,
      data // jobType 职务类型：1=会内组织，2=俱乐部，3=班级
    }).then((res) => {
      return res.list.map((item) => {
        return {
          label: item.value,
          value: item.key,
        };
      });
    })
  },
  /** 组织职务添加或修改 */
  ADD_OR_UPDATE_ORGANIZATION_JOB(data) {
    return request({
      url: url.orgManager.job.addOrUpdateOrganizationJob,
      data
    });
  },
  /** 组织职务删除 */
  DELETE_ORGANIZATION_JOB(data) {
    return request({
      url: url.orgManager.job.deleteOrganizationJob,
      data
    });
  },
  /** 组织职务排序 */
  SORT_ORGANIZATION_JOB(data) {
    return request({
      url: url.orgManager.job.sortOrganizationJob,
      data
    });
  },
  /** 组织职务状态修改 */
  UPDATE_ORGANIZATION_JOB_STATUS(data) {
    return request({
      url: url.orgManager.job.updateStatus,
      data
    });
  },
  /** 组织班级添加或修改 */
  ADD_OR_UPDATE_GRADE(data) {
    return request({
      url: url.orgManager.class.addOrUpdateGrade,
      data
    });
  },
  /** 组织班级修改状态值 */
  UPDATE_STATUS_GRADE(data) {
    return request({
      url: url.orgManager.class.updateStatus,
      data
    });
  },
  /** 组织班级删除 */
  DELETE_GRADE(data) {
    return request({
      url: url.orgManager.class.deleteGrade,
      data
    });
  },
  /** 组织班级修改排序值 */
  UPDATA_SORT_GRADE(data) {
    return request({
      url: url.orgManager.class.updataSort,
      data
    });
  },
  /** 意见反馈删除 */
  DELETE_OPINION_FEEDBACK(data) {
    return request({
      url: url.orgManager.feedback.deleteOpinion,
      data
    });
  },
});
