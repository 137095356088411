// TODO 校验身份证
export default function (rule, value, callback) {
  const { required = false } = rule;
  const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

  if (required && !value) {
    callback(new Error('身份证不能为空'));
  } else if (!regIdCard.test(value)) {
    callback(new Error('身份证号填写有误'));
  } else {
    callback();
  }
}
