// TODO 校验姓名是否为中文
export default function (rule, value, callback) {
  const { required = false } = rule;
  const reg = /^[\u4e00-\u9fa5 ]{1,10}$/;

  if (required && !value) {
    callback(new Error('姓名不能为空'));
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的姓名'));
  } else {
    callback();
  }
}
