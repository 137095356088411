// TODO 校验手机号格式是否正确
export default function (rule, value, callback) {
  const { required = false } = rule;
  const reg = /^1(3|4|5|6|7|8|9)\d{9}$/;

  if (required && !value) {
    callback(new Error('手机号不能为空'));
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
}
