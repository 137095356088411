/** 处理通用下拉选项数据 */
const handleSelectOption = (list) => {
  return (
    list.map((org) => {
      const { id, name } = org;

      return {
        label: name,
        value: id,
      };
    }) || []
  );
};

export default ({ request, url }) => ({
  /**
   * 通用 api
   * @param url 请求地址
   * @param data 请求数据
   */
  COMMON_REQUEST(url, data, options) {
    let _options = options || {};

    return request({
      url,
      data,
      ..._options,
    });
  },
  /**
   * @description 获取数据字典的数据
   * @param {Object} codes 数据字典的code
   */
  SYS_DICTIONARY(codes = []) {
    return request({
      url: url.dictionary,
      data: { codes },
    });
  },
  GET_SIGN(codes = []) {
    return request({
      url: url.getSign,
      data: { codes },
    });
  },
  USER_REMIND(data) {
    return request({
      url: url.todoUrl,
      data,
    });
  },
  /** 枚举下拉列 */
  QUERY_TYPE_LIST(data) {
    return request({
      url: url.queryTypeList,
      data,
    });
  },
  /** 获取所有组织 */
  GET_ALL_ORG(data) {
    return request({
      url: url.getAllOrg,
      data,
    }).then((res) => {
      return handleSelectOption(res);
    });
  },
  /** 获取所有组织职务 */
  GET_ALL_JOB(data) {
    return request({
      url: url.getAllJob,
      data
    }).then((res) => {
      return handleSelectOption(res.list);
    });
  },
  /** 企业类别列表 */
  GET_ALL_CATEGORY() {
    return request({
      url: url.getAllCategory,
    }).then((res) => {
      return handleSelectOption(res.list);
    });
  },
  /** 获取所有行业标签 */
  GET_ALL_INDUSTRY() {
    return request({
      url: url.getAllIndustry,
    }).then((res) => {
      return handleSelectOption(res.list);
    });
  },
  /** 获取所有企业标签 */
  async GET_ENTERPRISE_LABEL() {
    return request({
      url: url.getEnterpriseLabel,
    }).then((res) => {
      return handleSelectOption(res.list);
    });
  },
  /** 获取所有用户标签 */
  GET_USER_LABEL() {
    return request({
      url: url.getUserLabel,
    }).then((res) => {
      return handleSelectOption(res.list);
    });
  },
  /** 获取所有班级 */
  GET_ALL_GRADE() {
    return request({
      url: url.getAllGrade,
    }).then((res) => {
      return handleSelectOption(res.list);
    });
  },
  /** 获取所有用户 */
  LIST_USER_BASE(data) {
    return request({
      url: url.listUserBase,
      data
    }).then((res) => {
      return res.list.map((item) => {
        return {
          label: item.fullName,
          value: item.id,
          search: item.fullName + item.mobile,
          mobile: item.mobile
        }
      })
    });
  },
  /** 获取地址下拉列 */
  QUERY_ADDRESS() {
    return request({
      url: url.queryAddress,
    });
  },
});
