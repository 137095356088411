export default ({ request, url }) => ({
  //礼品分类
  /** 新增礼品分类 */
  GIFTALASSI_ADD(data) {
    return request({
      url: url.gift.classify.giftClassiAdd,
      data,
    });
  },
  /** 更新礼品分类 */
  GIFTALASSI_UPDATE(data) {
    return request({
      url: url.gift.classify.update,
      data,
    });
  },
  /** 更新礼品分类排序值 */
  GIFTALASSI_UPDATESORT(data) {
    return request({
      url: url.gift.classify.updateSort,
      data,
    });
  },
  /** 更新礼品分类状态 */
  GIFTALASSI_UPDATESTATUS(data) {
    return request({
      url: url.gift.classify.updateStatus,
      data,
    });
  },
  /** 获取礼品分类详情 */
  GIFTALASSI_GETINFOBYID(data) {
    return request({
      url: url.gift.classify.getInfoById,
      data,
    });
  },
  /** 获取礼品分类分页列表 */
  GIFTALASSI_GETPAGELIST(data) {
    return request({
      url: url.gift.classify.getPageList,
      data,
    });
  },
  /** 获取礼品分类下拉列表 */
  GIFTALASSI_GETCHOOSELIST(data) {
    return request({
      url: url.gift.classify.getChooseList,
      data,
    });
  },
  //礼品管理
  /** 新增礼品 */
  GETGIFT_GIFTADD(data) {
    return request({
      url: url.gift.manage.giftAdd,
      data,
    });
  },
  /** 更新礼品 */
  GETGIFT_UPDATE(data) {
    return request({
      url: url.gift.manage.update,
      data,
    });
  },
  /** 更新礼品状态 */
  GETGIFT_UPDATESTATUS(data) {
    return request({
      url: url.gift.manage.updateStatus,
      data,
    });
  },
  /** 更新排序值 */
  GETGIFT_UPDATESORT(data) {
    return request({
      url: url.gift.manage.updateSort,
      data,
    });
  },
  /** 获取详情数据 */
  GETGIFT_GETINFOBYID(data) {
    return request({
      url: url.gift.manage.getInfoById,
      data,
    });
  },
  /** 导出数据 */
  GETGIFT_EXPORTLIST(data) {
    return request({
      url: url.gift.manage.exportList,
      data,
      responseType: 'arraybuffer',
    });
  },
  /** 获取礼品选择分页列表 */
  GETGIFT_CHOOSE_PAGE(data) {
    return request({
      url: url.gift.manage.getChoosePageList,
      data,
    });
  },
  //审批管理
  /** 获取分页数据 */
  APPROVAL_GETPAGE(data) {
    return request({
      url: url.gift.examineAndApprove.getPage,
      data,
    });
  },
  /** 导出数据 */
  APPROVAL_EXPORTLIST(data) {
    return request({
      url: url.gift.examineAndApprove.exportList,
      data,
      responseType: 'arraybuffer',
    });
  },
  /** 客服审批 */
  APPROVAL_CUSTOMER_SERVEICE_APPROVAL(data) {
    return request({
      url: url.gift.examineAndApprove.customerServiceApproval,
      data,
    });
  },
  /** 财务审批 */
  APPROVAL_FINANCE_APPROVAL(data) {
    return request({
      url: url.gift.examineAndApprove.financeApproval,
      data,
    });
  },
  /** 客服批量审批 */
  APPROVAL_CUSTOMER_SERVICE_BATCH_APPROVAL(data) {
    return request({
      url: url.gift.examineAndApprove.customerServiceBatchApproval,
      data,
    });
  },
  /** 财务批量审批 */
  APPROVAL_FINANCE_BATCH_APPROVAL(data) {
    return request({
      url: url.gift.examineAndApprove.financeBatchApproval,
      data,
    });
  },
  /** 取消礼品兑换审批数据 */
  APPROVAL_CANCELLED(data) {
    return request({
      url: url.gift.examineAndApprove.cancelled,
      data,
    });
  },
  /** 新增礼品兑换 */
  APPROVAL_ADD_EXCHANGGE(data) {
    return request({
      url: url.gift.examineAndApprove.addExchange,
      data,
    });
  },
  //发货管理
  /** 导出数据 */
  SHIPMENTS_EXPORTDATA(data) {
    return request({
      url: url.gift.shipments.exportData,
      data,
      responseType: 'arraybuffer',
    });
  },
  /** 发货 */
  SHIPMENTS_SHIP(data) {
    return request({
      url: url.gift.shipments.ship,
      data,
    });
  },
  /** 确认收货 */
  SHIPMENTS_CONFIRM_RECEIPT(data) {
    return request({
      url: url.gift.shipments.confirmReceipt,
      data,
    });
  },
  /** 导入批量发货 */
  SHIPMENTS_IMPORT_SHIP(data) {
    return request({
      url: url.gift.shipments.importShip,
      data,
      responseType: 'arraybuffer',
    });
  },
});
