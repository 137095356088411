//广告管理
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
    {
        path: `${baseRoute}/advertising/manage`,
        name: 'advertisingManager',
        meta: {
            title: '广告管理',
            auth: true,
            cache: true,
        },
        component: _import('crm/advertising/manage'),
    },
    {
        path: `${baseRoute}/advertising/position`,
        name: 'advertisingPosition',
        meta: {
            title: '广告位管理',
            auth: true,
            cache: true,
        },
        component: _import('crm/advertising/position'),
    }
]