const marketingCampaignUrl = {
  marketingCampaign: {
    /* 营销活动 */
    manage: {
      /* 新增 */
      add: '/manage/marketing/activity/add',
      /* 更新 */
      update: '/manage/marketing/activity/update',
      /* 更新状态 */
      updateStatus: '/manage/marketing/activity/updateStatus',
      /* 删除 */
      delete: '/manage/marketing/activity/delete',
      /* 获取详情 */
      getInfoById: '/manage/marketing/activity/getInfoById',
      /* 设置虚拟礼品状态 */
      updateVirtualGiftStatus: '/manage/marketing/activity/updateVirtualGiftStatus',
      /* 营销活动列表 */
      getMarketingActivityPage: '/manage/marketing/activity/getMarketingActivityPage',
      /* 营销活动详情列表 */
      getMarketingActivityDetailsPage: '/manage/marketing/activity/getMarketingActivityDetailsPage',
    },
    /* 虚拟礼品 */
    virtualGift: {
      /* 新增 */
      addVirtualGift: '/manage/marketing/activity/virtualGift/add',
      /* 获取列表 */
      getList: '/manage/marketing/activity/virtualGift/getList',
      /* 删除 */
      delete: '/manage/marketing/activity/virtualGift/delete',
      /* 导入券码 */
      importCouponCode: '/manage/marketing/activity/virtualGift/importCouponCode',
    }
  },
};

export default marketingCampaignUrl;
