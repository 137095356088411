const advertisingURL = {
  advertising: {
    /** 广告列表 */
    getAdvertise: '/manage/zbAdvertisingManage/listAdvertisingManage',
    /** 新增广告 */
    addAdvertise: '/manage/zbAdvertisingManage/addOrUpdate',
    /** 广告详情 */
    advertiseDetail: '/manage/zbAdvertisingManage/getInfo',
    /** 广告删除 */
    deleteAdvertise: '/manage/zbAdvertisingManage/delete',

    /** 广告位列表 */
    getAdvertiseLocation: '/manage/zbAdvertisingLocation/listAdvertisingLocation',
    /** 新增广告位 */
    addAdvertiseLocation: '/manage/zbAdvertisingLocation/addOrUpdate',
    /** 广告位详情 */
    advertiseDetailLocation: '/manage/zbAdvertisingLocation/getInfo',
    /** 广告位删除 */
    deleteAdvertiseLocation: '/manage/zbAdvertisingLocation/delete',
  }
}

export default advertisingURL