import { useStore } from '@/store';
import useToken from './use-token';
import useUser from './use-user';
import useUUID from './use-uuid';
import { LogoutOptions } from '@/store/modules/d2admin/modules/account';

export default function useAccount() {
  const store = useStore();

  /** 清除账号信息 */
  const clearAccountInfo = async () => {
    const { setUUID } = useUUID();
    const { setToken } = useToken();
    const { setUser } = useUser();

    // 删除cookie
    setUUID('');
    setToken(null);
    // 清空 vuex 用户信息
    await setUser(null);
  };

  /** 登录 */
  const login = (data) => {
    return store.dispatch('d2admin/account/login', data);
  };

  /** 重新登录 */
  const relogin = () => {
    return store.dispatch('d2admin/account/relogin');
  };

  /** 退出登录 */
  const logout = (options) => {
    return store.dispatch('d2admin/account/logout', options);
  };

  return {
    /** 清除账号信息 */
    clearAccountInfo,
    /** 登录 */
    login,
    /** 重新登录 */
    relogin,
    /** 退出登录 */
    logout,
  };
}
