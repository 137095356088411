// import useCloud from "@/hooks/use-cloud";
// import useImageParse from "@/hooks/use-image-parse";
import { formatUserDataPromise } from '@/libs/crm/format';
export default ({ request, url }) => ({
  /** 导入客户 */
  IMPORT_CUSTOMER(data) {
    return request({
      url: url.customer.manage.importCustomer,
      data,
      responseType: 'arraybuffer',
    });
  },
  /** 导出客户 */
  EXPORT_CUSTOMER(data) {
    return request({
      url: url.customer.manage.exportCustomer,
      data,
      responseType: 'arraybuffer',
      timeout: 60000,
    });
  },
  /* 客户等级下拉列表 */
  LIST_LEVELRULE_LABEL() {
    return request({
      url: url.customer.level.getChooseList,
    }).then(res => {
      return (
        res.map(item => {
          return {
            label: item.name,
            value: item.id,
          };
        }) || []
      );
    });
  },
  /* 添加客户等级 */
  ADD_LEVELRULE(data) {
    return request({
      url: url.customer.level.addCustomerLevelRule,
      data,
    });
  },
  /* 更新客户等级 */
  UPDATE_LEVELRULE(data) {
    return request({
      url: url.customer.level.updateCustomerLevelRule,
      data,
    });
  },

  /* 删除客户等级 */
  DELETE_LEVEL_RULE(data) {
    return request({
      url: url.customer.level.deleteCustomerLevelRule,
      data,
    });
  },

  /* 更新h5账号状态 */
  UPDATE_STATUS_h5(data) {
    return request({
      url: url.customer.h5.updateStatus,
      data,
    });
  },

  /* 导出H5账号数据 */
  EXPORT_ACCOUNT_DATA() {
    return request({
      url: url.customer.h5.exportAccountData,
      responseType: 'arraybuffer',
    });
  },

  /* 客户H5账号审核 */
  AUDIT_ACCOUNT(data) {
    return request({
      url: url.customer.h5.auditAccount,
      data,
    });
  },

  /* 客户H5账号合并客户 */
  MERGE_ACCOUNT_CUSTOMER(data) {
    return request({
      url: url.customer.h5.mergeAccountCustomer,
      data,
    });
  },

  /* 获取认证中的冲突客户数据 */
  MERGE_ACCOUNT_CONFLICTCUSTOMERLIST(data) {
    return request({
      url: url.customer.h5.getConflictCustomerList,
      data,
    });
  },

  /** 客户详情 */
  GET_CUSTOMER_INFO(id) {
    return request({
      url: url.customer.manage.getCustomerInfo,
      data: { id },
    }).then(res => {
      return {
        ...res,
      };
    });
  },

  /** 新增客户 */
  ADD_CUSTOMER(data) {
    return request({
      url: url.customer.manage.addCustomer,
      data,
    });
  },
  /* 修改客户信息 */
  UPDATE_CUSTOMER(data) {
    return request({
      url: url.customer.manage.updateCustomer,
      data,
    });
  },
  /* 修改客户状态 */
  UPDATE_STATUS_CUSTOMER(data) {
    return request({
      url: url.customer.manage.updateCustomerStatus,
      data,
    });
  },
  /** 删除客户 */
  DELETE_CUSTOMER(id) {
    return request({
      url: url.customer.manage.deleteCustomer,
      data: { id },
    });
  },

  /** 转移客户 */
  TRANSFER_CUSTOMER(data) {
    return request({
      url: url.customer.manage.transferCustomer,
      data,
    });
  },

  /** 合并客户 */
  MERGE_CUSTOMER(data) {
    return request({
      url: url.customer.manage.mergeCustomer,
      data,
    });
  },

  /* 新增客户标签 */
  ADD_CUSTOMER_LABEL(data) {
    return request({
      url: url.customer.label.addCustomerLabel,
      data,
    });
  },
  /* 修改客户标签 */
  UPDATE_CUSTOMER_LABEL(data) {
    return request({
      url: url.customer.label.updateCustomer,
      data,
    });
  },

  /** 删除客户标签 */
  DELETE_CUSTOMER_LABEL(ids) {
    return request({
      url: url.customer.label.deleteCustomerLabel,
      data: { ids },
    });
  },

  /** 获取用户下拉列表 */
  GET_USER_CHOOSE_LIST(data) {
    return request({
      url: url.customer.manage.getUserChooseList,
      data,
    }).then(res => {
      return res;
    });
  },

  /** 获取客户的动态日志 */
  GET_CUSTOMER_DYNAMIC_LOG(data) {
    return request({
      url: url.customer.manage.getCustomerDynamicLog,
      data,
    }).then(res => {
      return res;
    });
  },

  /** 获取客户的合同 */
  GET_CUSTOMER_CONTRACT(data) {
    return request({
      url: url.customer.manage.getContractByCustomer,
      data,
    }).then(res => {
      return res;
    });
  },


  /** 获取客户理顾变动记录分页列表 */
  GET_CUSTOMER_LOPPAGE(data) {
    return request({
      url: url.customer.manage.getAgentUserLogPage,
      data,
    }).then(res => {
      return res;
    });
  },


  /** 获取活动选择会员客户分页列表 */
  GET_ACTIVITY_CHOOSE_CUSTOMER(data) {
    return request({
      url: url.customer.manage.getActivityChooseCustomerPage,
      data,
    }).then(res => {
      return res;
    });
  },

  /** 检查客户手机号唯一性 */
  CHECK_CUSTOMER_UNIQUE_MOBILE(data) {
    return request({
      url: url.customer.manage.checkCustomerUniqueMobile,
      data,
    });
  },
  /** 获取合同选择客户分页数据 */
  GET_CONTRAC_ChHOOSE_CUSTOMER_PAGE(data, type) {
    let re = request({
      url: url.customer.manage.getContractChooseCustomerPage,
      data,
    });
    formatUserDataPromise(re, type);
    return re;
  },
  /** 获取客户简短详情数据 */
  GET_CUSTOMER_SIMPLEINFO(data) {
    return request({
      url: url.customer.manage.getCustomerSimpleInfo,
      data,
    });
  },
  /** 没有合同客户转移 */
  TRANSFER_NONCONTACT_CUSTOMER(data) {
    return request({
      url: url.customer.manage.transferNonContactCustomer,
      data,
    });
  },
  /** 判断是否可以转移当前客户 */
  CHECK_TRANSFER_CUSTOMERDATA(data) {
    return request({
      url: url.customer.manage.checkTransferCustomerData,
      data,
    });
  },
  /** 判断是否可以转移当前客户 */
  GET_GIFTCHOOSE_CUSTOMER_PAGE(data) {
    return request({
      url: url.customer.manage.getGiftChooseCustomerPage,
      data,
    });
  },
});
