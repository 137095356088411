// 任务调度管理
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  {
    path: `${baseRoute}/task`,
    name: 'taskManage',
    meta: {
      title: '任务调度管理',
      auth: true,
      cache: true,
    },
    component: _import('crm/task'),
  }
]
