import { assign, map } from 'lodash-es';
// import faker from 'faker/locale/zh_CN';
import { service, request, serviceForMock, requestForMock } from './service';
import * as tools from './service/tools';
import url from './url';

const files = require.context('./modules', true, /\.api\.js$/);
const generators = files.keys().map(key => files(key).default);

export default assign(
  {},
  ...map(generators, (generator) =>
    generator({
      service,
      request,
      serviceForMock,
      requestForMock,
      // mock,
      // faker,
      tools,
      url,
    }),
  ),
);
