const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'rm';

export default [
    // 系统操作日志
    {
        path: `${baseRoute}/watch/log`,
        name: 'system-log',
        meta: {
            title: '操作日志',
            auth: true,
            cache: true,
        },
        component: _import('rm/system/watch/log'),
    },
]