<template>
  <el-dialog
    v-dialogDrag
    top="20vh"
    width="600px"
    v-loading="loading"
    title="待处理消息"
    :visible.sync="showDialog"
    :modal="false"
    @close="dialogClose"
  >
    <el-table :data="tableData" :row-style="{height: '20px'}" :cell-style="{padding: '6px 0'}" height="300">
      <el-table-column prop="menuName" label="项目" width="200"></el-table-column>
      <el-table-column label="待处理" width="100">
        <template slot-scope="scope">
          <div @click="routerChange(scope.row.menuUrl)">{{scope.row.doToCount}}</div>           
        </template>
      </el-table-column>   
      <el-table-column  width="100">
        <template #header>
          <div style="color: blue">将超时</div>
        </template>
        <template slot-scope="scope">
          <div style="color:blue" @click="routerChange(scope.row.menuUrl)" :class="{active:scope.row.leastCount>0?true:false}">{{scope.row.leastCount}}</div>
        </template>   
      </el-table-column>   
      <el-table-column width="100">
        <template #header>
          <div style="color: red">已超时</div>
        </template>
        <template slot-scope="scope">
          <div style="color: red" @click="routerChange(scope.row.menuUrl)" :class="{active:scope.row.overdueCount>0?true:false}">{{scope.row.overdueCount}}</div>   
        </template>
      </el-table-column>    
    </el-table>
  </el-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";

export default {
  name: "dialog-remind",
  props: {
    propsModel: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  components: {},
  mixins: [dialogMixin],
  data() {
    return {
      loading: false,
      tableData: [], //表格
      timer: null, //计时器
    };
  },
  computed: {},
  created() {},
  watch: {
    showDialog: {
      handler(val) {        
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    init(){
      this.loading = false;
      this.tableData = [];
      this.timer = null;
    },
    dialogClose() {},
    // TODO 获取列表
    loadData() {
      this.tableData = this.propsModel;
    },
    routerChange(url) {     
      this.$router.push(url)
    }
  },
};
</script>

<style lang="scss" scoped>
.active{
  text-decoration:underline
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-remind/components/dialog-remind.vue"
}
</vue-filename-injector>
