// 资讯管理
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const baseRoute = 'crm';

export default [
  {
    path: `${baseRoute}/info/manage`,
    name: 'infoManager',
    meta: {
      title: '资讯和视频管理',
      auth: true,
      cache: true,
    },
    component: _import('crm/info/manage/list'),
  },
  {
    path: `${baseRoute}/info/manage/add`,
    name: 'infoAdd',
    meta: {
      title: '新增/修改资讯',
      auth: true,
      cache: true,
    },
    component: _import('crm/info/manage/add'),
  },
  {
    path: `${baseRoute}/info/manage/view`,
    name: 'infoView',
    meta: {
      title: '资讯和视频详情',
      auth: true,
      cache: true,
    },
    component: _import('crm/info/manage/view'),
  },
  {
    path: `${baseRoute}/info/category`,
    name: 'infoCategory',
    meta: {
      title: '资讯和视频分类',
      auth: true,
      cache: true,
    },
    component: _import('crm/info/category'),
  },
  {
    path: `${baseRoute}/info/material`,
    name: 'infoMaterial',
    meta: {
      title: '素材库',
      auth: true,
      cache: true,
    },
    component: _import('crm/info/material'),
  },
  {
    path: `${baseRoute}/info/dailySentence`,
    name: 'dailySentence',
    meta: {
      title: '每日金句',
      auth: true,
      cache: true,
    },
    component: _import('crm/info/dailySentence'),
  },
];
