
export default ({ request, url }) => ({
  /** 导出积分 */
  EXPORT_AGENT_APPOINTMENT_DATA(data) {
    return request({
      url: url.agentAppointment.exportAgentAppointmentData,
      responseType: 'arraybuffer',
      data
    });
  },
  /** 批量调整积分 */
  DELETE_AGENT_APPOINTMENT(data) {
    return request({
      url: url.agentAppointment.deleteAgentAppointment,
      data
    });
  }
})
