export default ({ request, url }) => ({
  //  商户管理
  /** 新增 */
  MANAGEMENT_ADD(data) {
    return request({
      url: url.merchantManagement.manage.add,
      data,
    });
  },
  /** 更新 */
  MANAGEMENT_UPDATE(data) {
    return request({
      url: url.merchantManagement.manage.update,
      data,
    });
  },
  /** 删除 */
  MANAGEMENT_DELETE(data) {
    return request({
      url: url.merchantManagement.manage.delete,
      data,
    });
  },
  // 收付信息
  /** 生成订单号 */
  PAYMENTINFO_ADDVIRTUALGIFT() {
    return request({
      url: url.merchantManagement.receivingPaymentManagement.addVirtualGift,
    });
  },
  /** 新增付款单 */
  PAYMENTINFO_ADDPAYMENT(data) {
    return request({
      url: url.merchantManagement.receivingPaymentManagement.addPayment,
      data,
    });
  },
  /** 新增收款单 */
  PAYMENTINFO_ADDRECEIPT(data) {
    return request({
      url: url.merchantManagement.receivingPaymentManagement.addReceipt,
      data,
    });
  },
  /** 商户选择数据列表 */
  PAYMENTINFO_GETMERCHANTCHOOSELIST() {
    return request({
      url: url.merchantManagement.receivingPaymentManagement.getMerchantChooseList,
    });
  },
  /** 客户选择数据列表 */
  PAYMENTINFO_GETCUSTOMERCHPPSELIST() {
    return request({
      url: url.merchantManagement.receivingPaymentManagement.getCustomerChooseList,
    });
  },
  /** 收付信息统计数据 */
  PAYMENTINFO_GETSTATISTICS(data) {
    return request({
      url: url.merchantManagement.receivingPaymentManagement.getStatistics,
      data,
    });
  },
  /** 支付记录 */
  PAYMENTINFO_GETPAYMENTRECORD(data) {
    return request({
      url: url.merchantManagement.receivingPaymentManagement.getPaymentRecord,
      data,
    });
  },
  /** 客户钱包选择列表 */
  PAYMENTINFO_GETCUSTOMERWALLETCHOOSELIST(data) {
    return request({
      url: url.merchantManagement.receivingPaymentManagement.getCustomerWalletChooseList,
      data,
    });
  },
});
