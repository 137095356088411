import request from '@/plugin/axios'
import func from '@/maps/url/common/common'

// TODO 获取验证码
export function getVerifyCode(verifyId = '') {
    return request.post(func.getVerifyCode, {
        body: {
            verifyId, // 验证码Id，首次为null，后面刷新验证码时传参
        }
    }).then(res => {
        let { data = {} } = res;
        let { verifyId, verifyImg } = data;

        return {
            verifyId,
            verifyImg: `data:image/jpeg;base64,${verifyImg}`, // 拼接成base64图片
        }
    })
}

// TODO 校验验证码
export function checkVerifyCode(data) {
    return request.post(func.checkVerifyCode, {
        body: data
    }).then(res => {
        return Promise.resolve(true)
    }).catch(e => {
        return Promise.resolve(false)
    })
}

// TODO 获取常量列表
export function getEnumList(params) {
    let promise = new Promise(function (resolve, reject) {
        request.post(func.enumList, {
            body: params
        }).then(res => {
            let array = res.data;
            resolve(array);
        }).catch(res => {
            reject(res);
        });
    });
    return promise;
}

// TODO 获取常量列表
export function getEnumListAll(params) {
    let promise = new Promise(function (resolve, reject) {
        request.post(func.enumListAll, {
            body: params
        }).then(res => {
            let array = res.data;
            resolve(array);
        }).catch(res => {
            reject(res);
        });
    });
    return promise;
}